import LeftPage from "./left-page";
import Mobile from "./mobile";
import RightPage from "./right-page";
import { useWindowSize } from "react-use-size";

const HomePage = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1280;
  if(isMobile){
    return <Mobile />;
  }
  return (
    <div className="w-[900px] h-full flex items-center gap-5 relative">
      <LeftPage />
      <RightPage />
    </div>
  );
};
export default HomePage;
