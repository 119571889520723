import { IconCalendar, IconEmail, IconLocation, IconPhone } from "../../../components/icons";

/* eslint-disable react/no-unescaped-entities */
const Contact = () => {
    return (
      <div className="rounded-xl shadow-2xl w-full h-[500px] bg-white py-10 xl:px-8 px-4 gap-3 flex flex-col">
        <div className="flex items-center gap-5">
          <h1 className="text-24 font-bold">Contact</h1>
          <div className="w-[200px] h-[2px] bg-pink-800"></div>
        </div>
        <div className="flex flex-col justify-center items-start bg-grey-200 p-5 rounded-xl w-full mt-10 mb-2 gap-2">
        <div
          className="flex items-center justify-center gap-2 hover:cursor-pointer"
          // onClick={() => handleCopy("+84 0376382684", true)}
        >
          <button
            className="flex justify-center items-center bg-white p-2 rounded-lg h-full"
            type="button"
          >
            <IconPhone />
          </button>
          <div>
            <p className="text-12 font-semibold text-grey-600">Phone</p>
            <p className="text-14 text-grey-1000">(+84) 376382684</p>
          </div>
        </div>
        <div
          className="flex items-center justify-center gap-2 hover:cursor-pointer"
          // onClick={() => handleCopy("huanhust1998@gmail.com")}
        >
          <button
            className="flex justify-center items-center bg-white p-2 rounded-lg h-full"
            type="button"
          >
            <IconEmail />
          </button>
          <div>
            <p className="text-12 font-semibold text-grey-600">Email</p>
            <p className="text-14 text-grey-1000">huanhust1998@gmail.com</p>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2">
          <button
            className="flex justify-center items-center bg-white p-2 rounded-lg h-full"
            type="button"
          >
            <IconLocation />
          </button>
          <div>
            <p className="text-12 font-semibold text-grey-600">Location</p>
            <p className="text-14 text-grey-1000">Ha Noi, Viet Nam</p>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2">
          <button
            className="flex justify-center items-center bg-white p-2 rounded-lg h-full"
            type="button"
          >
            <IconCalendar />
          </button>
          <div>
            <p className="text-12 font-semibold text-grey-600">Birthday</p>
            <p className="text-14 text-grey-1000">20 May 1998</p>
          </div>
        </div>
      </div>
      </div>
    );
  };
  
  export default Contact;
  