import { avatar } from "../../../assets/images";
import About from "../right-page/about";
import Contact from "../right-page/contact";
import Resume from "../right-page/resume";

const Mobile = () => {
  return (
    <div className="w-full p-5 rounded-xl flex items-center flex-col h-[90%] gap-10">
      <div>
        <div className="drop-shadow-xl">
          <img src={avatar} alt="avt" className="rounded-md scale-75" />
        </div>
        <h1 className="font-bold text-24 text-grey-300">Ninh Xuân Huấn</h1>
        <div className="flex justify-center items-center px-4 py-2 rounded-xl bg-pink-700">
          <p className="font-semibold text-14 text-white">Frontend Developer</p>
        </div>
      </div>
      <div className="flex flex-col gap-5 mb-5">
        <About />
        <Resume />
        <Contact />
      </div>
    </div>
  );
};
export default Mobile;
